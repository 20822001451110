<template>
  <section>
    <v-flex xs12 class="ctk-top-banner">
      <v-img
        v-if="info.banners[0]"
        :src="info.banners[0].img"
        class="ctk-top-banner"
        >
          <v-layout wrap hidden-sm-and-down>
            <v-flex md12 class="banner-right-div">
              <div class="banner-text-div">
                <p class="banner-text-en">SOLUTION</p>
                <div class="banner-text-line"></div>
                <p class="banner-text-zh">解决方案</p>
              </div>
            </v-flex>
          </v-layout>
        </v-img>
    </v-flex>
    <div class="div-1280 top-breadcrumb">
      <v-layout wrap>
        <v-flex md6 class="breadcrumb-left hidden-sm-and-down">好睡眠解决方案</v-flex>
        <v-flex md6><v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs></v-flex>
      </v-layout>
    </div>
    <div class="div-1280 ctk-container">
      <div class="page-title wow fadeInUp">改善5个环境因素</div>
      <div class="page-subtitle wow fadeInUp">打造一个优质的睡眠环境</div>
      <v-card
        class="d-flex flex-wrap justify-space-between"
        flat
        tile
      >
        <v-card
          class="env-div wow fadeInUp"
          data-wow-delay="0.5s"
          v-for="item in envs" :key="item.key"
          flat
          tile
          >
            <div class="env-img"><img :src="'https://ctk-website.oss-cn-shanghai.aliyuncs.com/hsm3/scheme/env-' + item.key + '.png'"/></div>
            <div class="env-title">{{item.title}}</div>
            <div class="env-desc">{{item.desc}}</div>
        </v-card>
      </v-card>
    </div>
      <!-- <div class="m-middle-banner hidden-md-and-up">
        <img src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/hsm3/scheme/middle-m-banner.jpg">
      </div> -->
    <div class="middle-banner ctk-container">
      <div class="green-div">
        <div class="green-title wow fadeInUp">好睡眠睡眠环境功能改善解决方案</div>
        <div class="green-subtitle wow fadeInUp">让人人拥有好睡眠</div>
        <p class="green-p wow fadeInUp">好睡眠生态集睡眠环境学、人体工程学、智能化技术，改善影响睡眠的静环境、暗环境、眠环境和嗅环境四大功能，全时段动态监测守护睡眠健康，以科技、专业、服务提供睡眠环境功能改善解决方案。为用户提供领先的“三无”产品、“三无”服务，打造24小时全天候深睡环境，让人人拥有好睡眠。</p>
      </div>
    </div>
    <div class="div-1280 ctk-container" id="improve">
      <div class="page-title wow fadeInUp">四大睡眠环境功能改善</div>
      <div class="page-subtitle wow fadeInUp">打造24小时全天候深睡环境</div>
      <div>
        <v-tabs
          class="envs2-tabs"
          centered
          center-active
          hide-slider
          :show-arrows="false"
          v-model="env2"
        >
          <v-tab
            v-for="item in envs2"
            :key="item.key"
            :ripple="false"
          >
            {{ item.title }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="env2">
          <v-tab-item
            v-for="item in envs2"
            :key="item.key"
          >
            <v-layout wrap>
              <v-flex xs12 md5 pr-md-12 class="envs2-img"><img :src="'https://ctk-website.oss-cn-shanghai.aliyuncs.com/hsm3/scheme/env4-' + item.key + '.png'" /></v-flex>
              <v-flex xs12 md7 class="envs2-div">
                <v-layout wrap>
                  <v-flex xs12 class="envs2-title">
                    <div class="envs2-title-en hidden-sm-and-down">{{item.title_en}}</div>
                    <div class="envs2-title-zh">{{item.title}}</div>
                    <div class="envs2-title-line"></div>
                  </v-flex>
                  <v-flex xs6 md6 v-for="row in item.items" :key="row.key" class="envs2-item">
                    <div class="envs2-item-left"> <img :src="'https://ctk-website.oss-cn-shanghai.aliyuncs.com/hsm3/scheme/env4-' + item.key + '-' + row.key + '.svg'"/></div>
                    <div class="envs2-item-right">
                      <div class="envs2-item-title">{{row.title}}</div>
                      <div class="envs2-item-desc">{{row.desc}}</div>
                    </div>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>
    <div class="envs3-div envs3-div0" id="superiority">
      <div class="div-1280 ctk-container">
        <template>
          <swiper class="ctk-swiper" :options="swiperOption">
            <swiper-slide v-for="item in envs3" :key="item.key" class="slide">
              <div class="page-title envs3-page-title wow fadeInUp">{{item.title}}</div>
              <div class="page-subtitle envs3-page-subtitle wow fadeInUp">{{item.desc}}</div>
              <div class="div-844">
                <div class="envs3-h">{{item.h}}</div>
                <div class="envs3-p">{{item.p}}</div>
                <div class="d-flex flex-wrap justify-space-between">
                  <v-card v-for="row in item.items" :key="row.key" flat tile class="transparent">
                    <div class="envs3-img"><img :src="'https://ctk-website.oss-cn-shanghai.aliyuncs.com/hsm3/scheme/env3-' + item.key + '-' + row.key + '.svg'"/></div>
                    <div class="envs3-title">{{row.title}}</div>
                  </v-card>
                </div>
              </div>
            </swiper-slide>
            <div class="swiper-button-prev hidden-sm-and-down" slot="button-prev"></div>
            <div class="swiper-button-next hidden-sm-and-down" slot="button-next"></div>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </template>
      </div>
    </div>
    <div class="envs4-div">
      <div class="div-1280 ctk-container">
        <div class="page-title wow fadeInUp">{{envs4.title}}</div>
        <div class="page-subtitle wow fadeInUp">{{envs4.desc}}</div>
        <div class="d-flex flex-wrap justify-space-between">
          <v-card
            class="envs4-card"
            v-for="item in envs4.items" :key="item.key"
            flat
            tile
            >
              <div class="envs4-img wow fadeInUp" data-wow-delay="0.1s"><img :src="'https://ctk-website.oss-cn-shanghai.aliyuncs.com/hsm3/scheme/envb-' + item.key + '.png'"/></div>
              <div class="envs4-title wow fadeInUp" data-wow-delay="0.3s">{{item.title}}</div>
              <div class="envs4-desc wow fadeInUp" data-wow-delay="0.5s">{{item.desc}}</div>
          </v-card>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper/dist/vue-awesome-swiper.js'
export default {
  components: {
    swiper,
    swiperSlide
  },
  data: () => ({
    pageId: 15,
    innerWidth: window.innerWidth,
    breadcrumbs: [
      { text: '首页', disabled: false, href: '/' },
      { text: '解决方案', disabled: false, href: 'javascript:void(0)' },
      { text: '好睡眠解决方案', disabled: true }
    ],
    envs: [
      { key: 1, title: '声音', desc: '控制声音，营造安静舒适的睡眠环境。室外的噪音容易导致大脑亢奋和身体疲劳' },
      { key: 2, title: '光线', desc: '光线会影响大脑褪黑素的分泌，导致难以入睡和早醒' },
      { key: 3, title: '温度/湿度', desc: '卧室的理想睡眠温度为20℃ ~ 22℃ (*数据来源：《活出健康：免疫力就是好医生》)。维持室内的温度/湿度，有利于睡眠' },
      { key: 4, title: '床品', desc: '人与被褥、床垫、枕芯等所形成的近体床被气候，可维持被窝温度和湿度，令人感到很舒适，能有效延长深度睡眠时间' },
      { key: 5, title: '空气', desc: '清新的空气有助于提升睡眠品质，减少异味和闷热感，可有效提升睡眠质量' }
    ],
    env2: null,
    envs2: [
      {
        key: 1,
        title: '静环境',
        title_en: 'QUIET ENVIRONMENT',
        items: [
          { key: 1, title: '四层隔音', desc: '让您拥有安心舒适的太空舱' },
          { key: 2, title: '隔热保温', desc: '维持室内温度/湿度稳定' },
          { key: 3, title: '密封性好', desc: '防尘防霾 不漏水 墙壁窗帘不发霉' },
          { key: 4, title: '绚丽时尚', desc: '德国进口膜 让家焕然一新' }
        ]
      },
      {
        key: 2,
        title: '暗环境',
        title_en: 'DARK ENVIRONMENT',
        items: [
          { key: 1, title: '遮阳避光', desc: '“伸手不见五指”的漆黑' },
          { key: 2, title: '守护健康', desc: '消除窗帘带来的霉菌' },
          { key: 3, title: '加倍隔音', desc: '让睡眠环境静上加静' },
          { key: 4, title: '隔热保温', desc: '维持室内温度/湿度稳定' },
          { key: 5, title: '智能操控', desc: '室内光线和隐私安全随心控制' }
        ]
      },
      {
        key: 3,
        title: '眠环境',
        title_en: 'SLEEP ENVIRONMENT',
        items: [
          { key: 1, title: '零重力模式', desc: '快速放松身心 压力秒清零' },
          { key: 2, title: '科学护脊', desc: '创新五分区设计 智享舒适睡眠' },
          { key: 3, title: '打鼾干预', desc: '抬高15°顺畅呼吸 缓解打鼾' },
          { key: 4, title: '完美体验', desc: '五大舒享模式 睡觉休闲一张床搞定' },
          { key: 5, title: '智能操控', desc: '三种方式随心切换 操作简单无负担' },
          { key: 6, title: '健康环保', desc: '环保科技面料 质感高级亲肤透气' }
        ]
      },
      {
        key: 4,
        title: '嗅环境',
        title_en: 'OLFACTORY ENVIRONMENT',
        items: [
          { key: 1, title: '高效助眠', desc: '快速减缓压力 舒缓身心' },
          { key: 2, title: '恢复精力', desc: '让大脑皮层细胞充分休息' },
          { key: 3, title: '植物精粹', desc: '取自天然植物 健康助眠' },
          { key: 4, title: '安全可靠', desc: '三方机构检测 符合标准' }
        ]
      }
    ],
    envs3: [
      {
        key: 1,
        title: '好睡眠独特优势',
        desc: '为品质加冕',
        h: '“三无”产品',
        p: '为用户提供“无噪音污染”、“无漏光干扰”、“无霉菌影响”的“三无”产品，让用户能够安心睡个好觉，享受健康生活。',
        items: [
          { key: 1, title: '无声' },
          { key: 2, title: '无光' },
          { key: 3, title: '无霉' }
        ]
      },
      {
        key: 2,
        title: '好睡眠独特优势',
        desc: '为品质加冕',
        h: '“三无”服务”',
        p: '为用户提供“无尘的换装环境”、“无打扰的换装过程”、“无忧的全流程服务”的“三无”服务，让每个用户都能感受到好睡眠环境改造全程中省时、省心、省钱的体验。',
        items: [
          { key: 1, title: '无尘' },
          { key: 2, title: '无扰' },
          { key: 3, title: '无忧' }
        ]
      }
    ],
    envs4: {
      title: '拥有好睡眠',
      desc: '成就心中向往的未来',
      items: [
        { key: 1, title: '颜值梦', desc: '给太太好睡眠' },
        { key: 2, title: '成长梦', desc: '给孩子好睡眠' },
        { key: 3, title: '长寿梦', desc: '给父母好睡眠' },
        { key: 4, title: '事业梦', desc: '给先生好睡眠' }
      ]
    },
    swiperOption: {
      // direction: 'vertical',
      autoHeight: true,
      slidesPerView: 1,
      spaceBetween: 30,
      mousewheel: false,
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      on: {
        slideChange: function () {
          document.querySelector('.envs3-div').classList.remove('envs3-div0', 'envs3-div1')
          document.querySelector('.envs3-div').classList.add('envs3-div' + this.activeIndex)
        }
      }
    }
  }),
  created () {
    document.title = '好睡眠解决方案-好睡眠'
    document.querySelector('#menu_scheme').classList.add('v-btn--active')
  },
  mounted () {
    this.$wow.init()
    let hash = location.hash.slice(1)
    if (hash) {
      this.goAnchor(hash)
    }
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        this.info.banners = val.banner[this.pageId]
      },
      deep: true
    }
  },
  computed: {
    info () {
      let banners = {}
      banners[this.pageId] = []
      if (this.$store.getters.companyInfo.banner) {
        banners = this.$store.getters.companyInfo.banner[this.pageId]
      }
      return {
        banners: banners
      }
    }
  },
  methods: {
    goAnchor (selector) {
      let el = document.getElementById(selector)
      if (el) {
        el.scrollIntoView(true)
      }
    }
  }
}
</script>
<style lang="css">
  @import '~@/assets/css/scheme.css';
</style>
